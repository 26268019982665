import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import { getSection } from './pageTypeToSection';

const DrupalActivityPagesGenerator = ({ data }: any) => {
  const paragraphs = data.drupal.nodeById.paragraphs.map(getSection);
  const siteUrl = data.site.siteMetadata.siteUrl
  return (
    <Layout
      hasSocialMediaShareIcons
      language={data.drupal.nodeById.entityLanguage.id}
      pathEn={data.drupal.nodeById.entityUrl.en.path}
      pathRo={data.drupal.nodeById.entityUrl.ro.path}
      title={data.drupal.nodeById.entityLabel}
      metaTags={data.drupal.nodeById.metaTags}
      siteUrl={siteUrl}
      ogImage={data.drupal.nodeById.ogImage?.childImageSharp?.fluid?.src}
      type="activityPage"
    >
      {paragraphs}
    </Layout>
  );
};

export default DrupalActivityPagesGenerator;

export const pageQuery = graphql`
  query ($PageNid: String!, $PageLangCode: DRUPAL_LanguageId!, $CurrentPage: Int = 0) {
    drupal {
      nodeById(id: $PageNid, language: $PageLangCode) {
        ... on DRUPAL_NodeActivity {
          entityId
          entityLabel
          entityLanguage {
            id
          }
          entityUrl {
            en: translate(language: EN) {
              path
            }
            ro: translate(language: RO) {
              path
            }
          }
          fieldOgImage {
            alt
            entity {
              uri {
                url
              }
            }
          }
          ogImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
          metaTags: entityMetatags {
            ... on DRUPAL_MetaValue {
              key
              value
            }
            ... on DRUPAL_MetaLinkHreflang {
              key
              value
            }
            ... on DRUPAL_MetaItemProp {
              key
              value
            }
            ... on DRUPAL_MetaLink {
              key
              value
            }
            ... on DRUPAL_MetaHttpEquiv {
              key
              value
            }
            ... on DRUPAL_MetaProperty {
              key
              value
            }
          }
          paragraphs: fieldParagraphs {
            entity {
              entityLanguage {
                id
              }
              ...ParagraphGeneric
              ...ParagraphView
              ...ParagraphLongText
              ...ParagraphTestimonial
              ...ParagraphSingleImage
              ...ParagraphMultipleImages
              ...ParagraphContainer
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
